<template>
  <div class="pa-0 ma-0">
    <v-data-table :headers="headers" :items="alarmActives" style="width: 100%">
      <template v-slot:[`item.siloId`]="{ item }">
        {{
          getSiloById(item.siloId).name == undefined
            ? "N/A"
            : getSiloById(item.siloId).name
        }}
      </template>

      <template v-slot:[`item.alarmId`]="{ item }">
        {{
          getAlarmById(item.alarmId).name == undefined
            ? "N/A"
            : getAlarmById(item.alarmId).name
        }}
      </template>

      <template v-slot:[`item.type`]="{ item }">
        {{
          $t(
            `alarms.types.${
              getAlarmById(item.alarmId).type == undefined
                ? 0
                : getAlarmById(item.alarmId).type
            }`
          )
        }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ humanDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <confirm-dialog
          :title="$t('alarms.actives.acknowledge')"
          :description="$t('alarms.actives.acknowledgeDescription')"
          v-on:ok="acknowledge(item.alarmId, item.siloId)"
          :icon="true"
          icon-value="mdi-check-circle"
          v-if="permitted('Alarms.Acknowledge')"
        >
        </confirm-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "AlarmActiveList",

  data() {
    return {
      headers: [
        { text: this.$t("alarms.actives.fields.alarmId"), value: "alarmId" },
        { text: this.$t("alarms.actives.fields.siloId"), value: "siloId" },
        { text: this.$t("alarms.fields.type"), value: "type" },
        { text: this.$t("alarms.actives.fields.message"), value: "message" },
        {
          text: this.$t("alarms.actives.fields.createdAt"),
          value: "createdAt",
        },
        { text: "", value: "actions" },
      ],
    };
  },

  methods: {
    ...mapActions("alarms", [
      "getAlarmActives",
      "getAlarms",
      "acknowledgeAlarm",
    ]),
    ...mapActions("silos", ["getSilos"]),

    async acknowledge(alarmId, siloId) {
      await this.acknowledgeAlarm({ alarmId: alarmId, siloId: siloId });
      await this.getAlarmActives();
    },
  },

  computed: {
    ...mapState("alarms", ["alarmActives"]),
    ...mapGetters("silos", ["getSiloById"]),
    ...mapGetters("alarms", ["getAlarmById"]),
  },

  async created() {
    await this.getSilos();
    await this.getAlarms();
    await this.getAlarmActives();
  },
};
</script>